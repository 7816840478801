import React from "react";
import { useTranslation } from "react-i18next";

const Instructions = () => {
    const { t } = useTranslation();
    return (
        <div className="side-panel align-self-start">
            <p className="instruction-title">{t("instruction")}</p>
            <ul className="instruction-list">
                <li className="instruction">{t("instruction 1")}</li>
                <li className="instruction">{t("instruction 2")}</li>
                <li className="instruction">{t("instruction 3")}</li>
            </ul>
            <hr />
            <ul className="instruction-list">
                <li className="instruction">{t("instruction 4")}</li>
                <li className="instruction">{t("instruction 5")}</li>
            </ul>
        </div>
    );
};

export default Instructions;
