import React, { useRef, useState, useEffect } from "react";
import { Form, Container, Row, Col, ListGroup } from "react-bootstrap";
import { Upload, CheckLg } from "react-bootstrap-icons";
import { useMsal } from "@azure/msal-react";
import { BlobServiceClient } from "@azure/storage-blob";
import ProgressBar from "react-bootstrap/ProgressBar";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import CustomError from "../util/CustomError";
import { Operation, Status, Source } from "../util/AuditUtil";
import SparkMD5 from "spark-md5";
import { Buffer } from "buffer";
import EmailList from "./EmailList";

const UploadSection = ({ sessionId }) => {
    const { instance, accounts } = useMsal();
    const inputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [destinationOptions, setDestinationOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedSequecingType, setSelectedSequecingType] = useState("");
    const [uploadedFileCount, setUploadedFileCount] = useState(0);
    const [cancelUpload, setCancelUpload] = useState(false);
    const [emails, setEmails] = useState([]);
    const uploadCancelledRef = useRef(cancelUpload);
    const [tries, setTries] = useState(0);
    const { t } = useTranslation();
    const [error, setError] = useState(null);
    uploadCancelledRef.current = cancelUpload;

    class MyTokenCredential {
        constructor(accessToken, expiresOn, saScope) {
            this.accessToken = accessToken;
            this.expiresOn = expiresOn;
            this.saScope = saScope;
        }

        setToken(accessToken, expiresOn) {
            this.accessToken = accessToken;
            this.expiresOn = expiresOn;
        }

        async getToken(scopes) {
            if (this.accessToken && new Date() < this.expiresOn) {
                return {
                    token: this.accessToken,
                    expiresOnTimestamp: this.expiresOn.getTime(),
                };
            } else {
                await this.refreshToken();
                return {
                    token: this.accessToken,
                    expiresOnTimestamp: this.expiresOn.getTime(),
                };
            }
        }

        async refreshToken() {
            try {
                const response = await instance.acquireTokenSilent({
                    ...this.saScope,
                    account: accounts[0],
                });

                this.accessToken = response.accessToken;
                this.expiresOn = response.expiresOn;
            } catch (error) {
                setError(error);
            }
        }
    }

    useEffect(() => {
        if (accounts.length !== 0) {
            const interfaceScope = {
                scopes: [process.env.REACT_APP_INTERFACE_SCOPE],
            };
            instance
                .acquireTokenSilent({
                    ...interfaceScope,
                    account: accounts[0],
                })
                .then((response) => {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${response.accessToken}`,
                        },
                    };
                    axios
                        .get(process.env.REACT_APP_INTERFACE_URL, config)
                        .then((response) => {
                            setDestinationOptions(response.data.destinations);
                            if (response.data.destinations.length > 0) {
                                setSelectedOption("0");
                                setEmails(
                                    response.data.destinations[0]
                                        .email_addresses
                                );
                            } else {
                                throw new CustomError(
                                    "There are no BDIP Drop destinations assigned to your user.",
                                    "ERR_USER_MISSING_PERMISSION"
                                );
                            }
                        })
                        .catch((e) => {
                            setError(e);
                            setDestinationOptions([]);
                        });
                });
        }
    }, [accounts, instance, sessionId]);

    const callAudit = (body) => {
        const interfaceScope = {
            scopes: [process.env.REACT_APP_INTERFACE_SCOPE],
        };
        instance
            .acquireTokenSilent({
                ...interfaceScope,
                account: accounts[0],
            })
            .then((response) => {
                const config = {
                    headers: {
                        Authorization: `Bearer ${response.accessToken}`,
                    },
                };
                axios.post(process.env.REACT_APP_AUDIT_URL, body, config);
            });
    };

    const handleClick = () => {
        inputRef.current?.click();
    };

    const selectDirectory = (event) => {
        setTries((prevTries) => prevTries + 1);

        setFiles([]);
        setUploading(true);
        setProgress(0);
        setUploadedFileCount(0);

        const selectedFiles = event.target.files;

        const SaScope = {
            scopes: [process.env.REACT_APP_SA_SCOPE],
        };

        instance
            .acquireTokenSilent({
                ...SaScope,
                account: accounts[0],
            })
            .then(async (response) => {
                const credential = new MyTokenCredential(
                    response.accessToken,
                    response.expiresOn,
                    SaScope
                );

                const blobUrl = destinationOptions[Number(selectedOption)].url;
                const partnerGuid =
                    destinationOptions[Number(selectedOption)].partner.guid;
                const projectGuid =
                    destinationOptions[Number(selectedOption)].guid;
                const integrationUrl =
                    destinationOptions[Number(selectedOption)].integration.url;
                const integrationName =
                    destinationOptions[Number(selectedOption)].integration.name;
                const fileFilters =
                    destinationOptions[Number(selectedOption)].sequence_upload;
                const submissionGuid = uuidv4();
                const path = `${projectGuid}/${submissionGuid}/`;
                const client = new BlobServiceClient(blobUrl, credential);
                const ChunkSize4MB = 1024 * 1024 * 4;

                let fileCount = 0;
                let filePaths = [];

                if (selectedSequecingType === "all") {
                    fileCount = selectedFiles.length;
                    for (const file of selectedFiles) {
                        const parts = file.webkitRelativePath.split("/");
                        parts.shift();
                        setFiles((oldArray) => [
                            ...oldArray,
                            {
                                filepath: parts.join("/"),
                                file: file,
                            },
                        ]);
                        filePaths.push(parts.join("/"));
                    }
                } else {
                    for (const file of selectedFiles) {
                        if (checkFileMatch(file, fileFilters)) {
                            fileCount++;
                            const parts = file.webkitRelativePath.split("/");
                            parts.shift();
                            setFiles((oldArray) => [
                                ...oldArray,
                                {
                                    filepath: parts.join("/"),
                                    file: file,
                                },
                            ]);
                            filePaths.push(parts.join("/"));
                        }
                    }
                }

                callAudit({
                    timestamp: new Date().toISOString(),
                    username: accounts[0].username,
                    operation: Operation.UPLOAD,
                    status: Status.START,
                    source: Source,
                    destination: `${blobUrl}/${partnerGuid}/${projectGuid}`,
                    session_id: sessionId,
                    submission_id: submissionGuid,
                    files: filePaths,
                    status_message: "File filters: " + fileFilters,
                });

                let index = 0;
                for (const file of selectedFiles) {
                    if (uploadCancelledRef.current) {
                        console.log("Upload cancelled.");
                        setCancelUpload(false);
                        setUploading(false);
                        callAudit({
                            timestamp: new Date().toISOString(),
                            username: accounts[0].username,
                            operation: Operation.UPLOAD,
                            status: Status.CANCEL,
                            source: Source,
                            destination: `${blobUrl}/${partnerGuid}/${projectGuid}`,
                            session_id: sessionId,
                            submission_id: submissionGuid,
                            files: filePaths,
                            status_message: "Cancel on file: " + file.filepath,
                        });
                        return;
                    }
                    if (selectedSequecingType === "all") {
                        const parts = file.webkitRelativePath.split("/");
                        parts.shift();
                        const blobName = parts.join("/");

                        const chunks = sliceFile(file, ChunkSize4MB);
                        const fileType = file.type;

                        callAudit({
                            timestamp: new Date().toISOString(),
                            username: accounts[0].username,
                            operation: Operation.UPLOAD,
                            status: Status.PENDING,
                            source: Source,
                            destination: `${blobUrl}/${partnerGuid}/${projectGuid}`,
                            session_id: sessionId,
                            submission_id: submissionGuid,
                            files: filePaths,
                            status_message: "Current file: " + blobName,
                        });

                        await uploadChunks(
                            client,
                            partnerGuid,
                            path + blobName,
                            chunks,
                            fileType
                        );

                        index++;
                        setUploadedFileCount(index);
                        setProgress(
                            convertTwoDecimal((index / fileCount) * 100)
                        );
                    } else {
                        if (checkFileMatch(file, fileFilters)) {
                            const parts = file.webkitRelativePath.split("/");
                            parts.shift();
                            const blobName = parts.join("/");

                            const chunks = sliceFile(file, ChunkSize4MB);
                            const fileType = file.type;

                            callAudit({
                                timestamp: new Date().toISOString(),
                                username: accounts[0].username,
                                operation: Operation.UPLOAD,
                                status: Status.PENDING,
                                source: Source,
                                destination: `${blobUrl}/${partnerGuid}/${projectGuid}`,
                                session_id: sessionId,
                                submission_id: submissionGuid,
                                files: filePaths,
                                status_message: "Current file: " + blobName,
                            });

                            await uploadChunks(
                                client,
                                partnerGuid,
                                path + blobName,
                                chunks,
                                fileType
                            );

                            index++;
                            setUploadedFileCount(index);
                            setProgress(
                                convertTwoDecimal((index / fileCount) * 100)
                            );
                        }
                    }
                    if (uploadCancelledRef.current) {
                        console.log("Upload cancelled.");
                        setCancelUpload(false);
                        setUploading(false);
                        callAudit({
                            timestamp: new Date().toISOString(),
                            username: accounts[0].username,
                            operation: Operation.UPLOAD,
                            status: Status.CANCEL,
                            source: Source,
                            destination: `${blobUrl}/${partnerGuid}/${projectGuid}`,
                            session_id: sessionId,
                            submission_id: submissionGuid,
                            files: filePaths,
                            status_message: "Cancel on file: " + file.filepath,
                        });
                        return;
                    }
                }
                setCancelUpload(false);
                setUploading(false);

                if (index === fileCount) {
                    if (accounts.length !== 0) {
                        const interfaceScope = {
                            scopes: [process.env.REACT_APP_INTERFACE_SCOPE],
                        };
                        instance
                            .acquireTokenSilent({
                                ...interfaceScope,
                                account: accounts[0],
                            })
                            .then((response) => {
                                const config = {
                                    headers: {
                                        Authorization: `Bearer ${response.accessToken}`,
                                    },
                                };
                                const body = {
                                    submission_id: submissionGuid,
                                    source_container: partnerGuid,
                                    source_dirs: projectGuid,
                                    integration_name: integrationName,
                                    email_addresses: emails,
                                };
                                axios
                                    .post(
                                        `https://${integrationUrl}/api/Start?code=bH5Vo8zeO-ktUKRaazWrw6R7Enfmn1QcIJSHgGEFV7VEAzFuM_jmKA==`,
                                        body,
                                        config
                                    )
                                    .then((response) => {
                                        axios.post(
                                            process.env.REACT_APP_AUDIT_URL,

                                            {
                                                timestamp:
                                                    new Date().toISOString(),
                                                username: accounts[0].username,
                                                operation: Operation.UPLOAD,
                                                status: Status.SUCCESS,
                                                source: Source,
                                                destination: `${blobUrl}/${partnerGuid}/${projectGuid}`,
                                                session_id: sessionId,
                                                submission_id: submissionGuid,
                                                files: filePaths,
                                            },
                                            config
                                        );
                                    })
                                    .catch((e) => {
                                        setError(e);
                                    });
                            });
                    }
                }
            });
    };

    const sliceFile = (file, chunkSize) => {
        const chunks = [];
        let offset = 0;

        while (offset < file.size) {
            const chunk = file.slice(offset, offset + chunkSize);
            chunks.push(chunk);
            offset += chunkSize;
        }

        return chunks;
    };

    const calculateMD5 = async (chunks) => {
        const spark = new SparkMD5.ArrayBuffer();
        for (const chunk of chunks) {
            const arrayBuffer = await chunk.arrayBuffer();
            spark.append(arrayBuffer);
        }
        return spark.end();
    };

    const uploadChunks = async (
        blobServiceClient,
        containerName,
        fileName,
        chunks,
        fileType
    ) => {
        const containerClient =
            blobServiceClient.getContainerClient(containerName);
        const blockBlobClient = containerClient.getBlockBlobClient(fileName);
        const md5Hash = await calculateMD5(chunks);

        const concurrency = 16;
        const chunkPromises = [];

        for (let i = 0; i < chunks.length; i += concurrency) {
            const chunkSlice = chunks.slice(i, i + concurrency);
            const promises = chunkSlice.map(async (chunk, index) => {
                if (uploadCancelledRef.current) {
                    setCancelUpload(false);
                    setUploading(false);
                    return;
                }
                const blockId = btoa(String(i + index).padStart(6, "0"));
                const contentLength = chunk.size;
                await blockBlobClient.stageBlock(
                    blockId,
                    chunk,
                    contentLength,
                    {
                        blobHTTPHeaders: { blobContentType: fileType },
                    }
                );
            });
            chunkPromises.push(Promise.all(promises));
        }
        await Promise.all(chunkPromises);

        const blockIds = [];
        for (let i = 0; i < chunks.length; i++) {
            blockIds.push(btoa(String(i).padStart(6, "0")));
        }
        await blockBlobClient.commitBlockList(blockIds, {
            blobHTTPHeaders: {
                blobContentMD5: Buffer.from(md5Hash, "hex"),
            },
        });
    };

    const convertTwoDecimal = (number) => {
        return Math.round(number * 100) / 100;
    };

    const fileMatchesFilter = (fileName, filters) => {
        const fileExtension = fileName.split(".").slice(1).join(".");
        return filters.some((filter) => {
            return fileExtension.endsWith(filter) || fileName === filter;
        });
    };

    const checkFileMatch = (file, fileFilters) => {
        const filters = fileFilters.split(", ");
        return (
            (filters.length === 1 && filters[0].length === 0) ||
            fileMatchesFilter(file.name, filters)
        );
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        setEmails(
            destinationOptions[Number(event.target.value)].email_addresses
        );
    };

    const handleSelectSequencingChange = (event) => {
        setSelectedSequecingType(event.target.value);
    };

    const handleCancelUpload = () => {
        setCancelUpload(true);
    };

    const addEmail = (email) => {
        setEmails([...emails, email]);
    };

    const deleteEmail = (index) => {
        const updatedEmails = emails.filter((email, i) => i !== index);
        setEmails(updatedEmails);
    };

    if (error) {
        throw error;
    }

    return (
        <div className="upload-section">
            <Container>
                <Row>
                    <Col className="d-flex" md={3}>
                        <Form.Label
                            htmlFor="desitinationSelect"
                            className="d-flex row-content-center"
                        >
                            {t("destination")}
                        </Form.Label>
                    </Col>
                    <Col md={9}>
                        <Form.Select
                            onChange={handleSelectChange}
                            value={selectedOption}
                            id="desitinationSelect"
                        >
                            {destinationOptions.map((option, index) => (
                                <option key={index} value={index}>
                                    {option.partner.display_name} -{" "}
                                    {option.display_name}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col className="d-flex" md={3}>
                        <Form.Label
                            htmlFor="uploadType"
                            className="d-flex row-content-center"
                        >
                            {t("uploading type")}
                        </Form.Label>
                    </Col>
                    <Col md={9}>
                        <Form.Select
                            onChange={handleSelectSequencingChange}
                            value={selectedSequecingType}
                            id="uploadType"
                        >
                            <option key="sequencing" value="sequencing">
                                {t("sequencing upload")}
                            </option>
                            <option key="all" value="all">
                                {t("upload all")}
                            </option>
                        </Form.Select>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col className="d-flex" md={3}>
                        <span className="d-flex row-content-center">
                            {t("files to be uploaded")}
                        </span>
                    </Col>
                    <Col className="file-ext" md={9}>
                        <span id="fileExt">
                            {selectedSequecingType === "all"
                                ? t("upload all")
                                : destinationOptions.length > 0
                                  ? destinationOptions[Number(selectedOption)]
                                        .sequence_upload
                                  : ""}
                        </span>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col className="d-flex" md={3}>
                        <Form.Label htmlFor="emailList" className="email-label">
                            {"Email Notifications:"}
                        </Form.Label>
                    </Col>
                    <Col md={9}>
                        <EmailList
                            emails={emails}
                            addEmail={addEmail}
                            deleteEmail={deleteEmail}
                        />
                    </Col>
                </Row>
                <Form.Group controlId="fileInput" className="d-none">
                    <Form.Label>File Input</Form.Label>
                    <div>
                        <input
                            id="fileInput"
                            key={tries}
                            ref={inputRef}
                            onChange={selectDirectory}
                            type="file"
                            name="fileList"
                            webkitdirectory=""
                            multiple
                        />
                    </div>
                </Form.Group>
                <br />
                <Row className="select-dir-button">
                    <button
                        disabled={uploading}
                        onClick={handleClick}
                        className="btn btn-dark"
                    >
                        {uploading ? t("uploading") : t("select")}
                    </button>
                </Row>
                <br />
            </Container>

            {files.length > 0 ? (
                <>
                    <Container>
                        <Row className="select-dir-button">
                            <button
                                disabled={!uploading}
                                onClick={handleCancelUpload}
                                className="btn btn-dark"
                            >
                                {cancelUpload && uploading
                                    ? t("canceling")
                                    : uploading
                                      ? t("cancel")
                                      : progress === 100
                                        ? t("finished")
                                        : t("canceled")}
                            </button>
                        </Row>
                        <br />
                        <Row>
                            <Col className="d-flex" md={6}>
                                <label className="d-flex row-content-center">
                                    {t("uploaded")} {uploadedFileCount}/
                                    {files.length}
                                </label>
                                {uploading ? (
                                    <div className="spinner">
                                        <Spinner
                                            animation="border"
                                            role="status"
                                            size="sm"
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Col>
                            <Col md={6}>
                                <ProgressBar
                                    className="custom-progress"
                                    animated={uploading}
                                    variant={
                                        progress === 100
                                            ? "success"
                                            : uploading
                                              ? "info"
                                              : "warning"
                                    }
                                    striped
                                    now={progress}
                                />
                            </Col>
                        </Row>
                        <br />
                        <div className="file-list">
                            <ListGroup as="ul" className="upload-list">
                                {files.map((file, index) => (
                                    <ListGroup.Item as="li" key={file.filepath}>
                                        {uploadedFileCount > index ? (
                                            <CheckLg />
                                        ) : (
                                            <Upload />
                                        )}
                                        <span> </span>
                                        {file.filepath + " "}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    </Container>
                </>
            ) : null}
        </div>
    );
};

export default UploadSection;
