import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { LanguageButton } from "./LanguageButton";

const HeaderSection = () => {
    return (
        <>
            <Navbar className="navbar-style container">
                <Navbar.Brand href="/">
                    <img
                        alt="Government of Canada"
                        src="https://www.canada.ca/etc/designs/canada/wet-boew/assets/sig-blk-en.svg"
                        height="40px"
                    />
                </Navbar.Brand>
                <div className="collapse navbar-collapse justify-content-end">
                    <LanguageButton />
                </div>
            </Navbar>
            <hr />
        </>
    );
};

export default HeaderSection;
