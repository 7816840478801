import React, { useState } from "react";
import { Form, Button, ListGroup, Alert, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const EmailList = ({ emails, addEmail, deleteEmail }) => {
    const [newEmail, setNewEmail] = useState("");
    const [error, setError] = useState("");
    const { t } = useTranslation();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleAddEmail = () => {
        if (newEmail.trim() === "") {
            setError(t("email empty"));
            return;
        }

        if (!emailRegex.test(newEmail.trim())) {
            setError(t("email invalid"));
            return;
        }

        addEmail(newEmail.trim());
        setNewEmail("");
        setError("");
    };

    const handleEmailChange = (e) => {
        setNewEmail(e.target.value);
        setError("");
    };

    return (
        <>
            <InputGroup>
                <Form.Control
                    className="lighter-placeholder"
                    type="email"
                    placeholder={t("email placeholder")}
                    value={newEmail}
                    onChange={handleEmailChange}
                    id="emailList"
                />
                <Button variant="primary" onClick={handleAddEmail}>
                    Add
                </Button>
            </InputGroup>
            {error && (
                <Alert variant="danger" className="mt-3">
                    {error}
                </Alert>
            )}
            <br />
            <ListGroup>
                {emails.map((email, index) => (
                    <ListGroup.Item
                        key={index}
                        className="d-flex justify-content-between align-items-center"
                    >
                        {email}
                        <Button
                            variant="danger"
                            onClick={() => deleteEmail(index)}
                        >
                            X
                        </Button>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </>
    );
};

export default EmailList;
