import React from "react";
import { useTranslation } from "react-i18next";

export const LanguageButton = () => {
    const { i18n, t } = useTranslation();

    const toggleLanguage = () => {
        const newLanguage = i18n.language === "en" ? "fr" : "en";
        i18n.changeLanguage(newLanguage);
    };

    return (
        <button onClick={toggleLanguage} className="language-button">
            {t("language switch")}
        </button>
    );
};
