import React from "react";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import CopyableAlert from "./CopyableAlert";

const ErrorFallback = ({ error }) => {
    const { t } = useTranslation();

    return (
        <>
            {
                <>
                    <Alert key={"danger"} variant={"danger"}>
                        <h3>{t("error title 1")}</h3>
                        <h5>{t("error title 2")}</h5>
                        <span>{t("error description")}</span>
                        <br />
                        <br />
                        <Alert
                            key={"secondary"}
                            variant={"secondary"}
                            className="error-inner-block"
                        >
                            <h6 className="underline-heading">Error code:</h6>
                            <CopyableAlert>
                                <span>Code: {error.code}</span>
                                <br />
                                <br />
                                <span>Message: {error.message}</span>
                                <br />
                                <br />
                                <span>Stack: {error.stack}</span>
                            </CopyableAlert>
                        </Alert>
                    </Alert>
                </>
            }
        </>
    );
};

export default ErrorFallback;
