import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

export const SignOutButton = () => {
    const { instance } = useMsal();
    const { t } = useTranslation();

    const handleLogout = () => {
        instance.logoutPopup({
            postLogoutRedirectUri: "/",
        });
    };

    return (
        <Button
            className="justify-content-end sharp-corner"
            variant="dark"
            onClick={() => handleLogout()}
        >
            {t("logout")}
        </Button>
    );
};
