import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./ErrorFallback";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { Operation, Status, Source } from "../util/AuditUtil";

function ErrorBoundary({ FallbackComponent, onError, children, sessionId }) {
    const { instance, accounts } = useMsal();
    const handleError = (error, info) => {
        const interfaceScope = {
            scopes: [process.env.REACT_APP_INTERFACE_SCOPE],
        };

        const body = {
            timestamp: new Date().toISOString(),
            username: accounts[0].username,
            operation: Operation.UPLOAD,
            status: Status.ERROR,
            source: Source,
            destination: "Tenant: " + accounts[0].idTokenClaims.tid,
            session_id: sessionId,
            status_message: `${error}`,
        };

        instance
            .acquireTokenSilent({
                ...interfaceScope,
                account: accounts[0],
            })
            .then((response) => {
                const config = {
                    headers: {
                        Authorization: `Bearer ${response.accessToken}`,
                    },
                };
                axios.post(process.env.REACT_APP_AUDIT_URL, body, config);
            });
    };

    return (
        <ReactErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={handleError}
        >
            {children}
        </ReactErrorBoundary>
    );
}

export default ErrorBoundary;
