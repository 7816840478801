import React, { useState, useEffect } from "react";
import "./styles/App.css";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import UploadSection from "./components/UploadSection";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import Instructions from "./components/Instructions";
import HeaderSection from "./components/HeaderSection";
import FooterSection from "./components/FooterSection";
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { SignInButton } from "./components/SignInButton";
import { SignOutButton } from "./components/SignOutButton";
import ErrorBoundary from "./components/ErrorBoundary";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Operation, Status, Source } from "./util/AuditUtil";

export default function App() {
    const { t } = useTranslation();
    const { accounts, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [sessionId, setSessionId] = useState("empty");

    useEffect(() => {
        if (accounts.length !== 0) {
            const currId = uuidv4();
            setSessionId(currId);
            const auditScope = {
                scopes: [process.env.REACT_APP_INTERFACE_SCOPE],
            };
            instance
                .acquireTokenSilent({
                    ...auditScope,
                    account: accounts[0],
                })
                .then((response) => {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${response.accessToken}`,
                        },
                    };
                    const body = {
                        timestamp: new Date().toISOString(),
                        username: accounts[0].username,
                        operation: Operation.LOGIN,
                        status: Status.SUCCESS,
                        source: Source,
                        destination: "Tenant: " + accounts[0].idTokenClaims.tid,
                        session_id: currId,
                    };
                    axios.post(process.env.REACT_APP_AUDIT_URL, body, config);
                });
        } else {
            setSessionId("empty");
        }
    }, [accounts, instance]);

    return (
        <>
            <header>
                <HeaderSection />
            </header>
            <main>
                <div className="App">
                    <Container className="container-align">
                        <Row>
                            <Col className="d-flex" md={4}>
                                <h1>BDIP Drop</h1>
                            </Col>
                            <Col
                                className="d-flex align-items-center justify-content-end"
                                md={8}
                            >
                                {accounts.length !== 0 ? (
                                    <p className="welcome">
                                        {t("welcome")}{" "}
                                        <span className="bold-text">
                                            {accounts[0].username}
                                        </span>
                                    </p>
                                ) : (
                                    <span></span>
                                )}
                                <div>
                                    {isAuthenticated ? (
                                        <SignOutButton />
                                    ) : (
                                        <SignInButton />
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <br />
                        <Container fluid>
                            <p>{t("app description")}</p>
                        </Container>
                        <AuthenticatedTemplate>
                            <br />
                            <Container fluid>
                                <Row>
                                    {/* Side Panel */}
                                    <Col
                                        className="d-flex align-items-start flex-column"
                                        md={3}
                                    >
                                        <Instructions></Instructions>
                                    </Col>
                                    {/* Main Content */}
                                    <Col md={9}>
                                        <ErrorBoundary sessionId={sessionId}>
                                            <UploadSection
                                                sessionId={sessionId}
                                            />
                                        </ErrorBoundary>
                                    </Col>
                                </Row>
                            </Container>
                        </AuthenticatedTemplate>

                        <UnauthenticatedTemplate>
                            <br />
                            <Container>
                                <h5>{t("need sign-in")}</h5>
                            </Container>
                        </UnauthenticatedTemplate>
                    </Container>
                </div>
            </main>
            <br />
            <footer>
                <hr />
                <FooterSection />
            </footer>
        </>
    );
}
