import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

export const SignInButton = () => {
    const { instance } = useMsal();
    const { t } = useTranslation();

    const handleLogin = () => {
        const scope = {
            scopes: [process.env.REACT_APP_LOGIN_SCOPE],
        };
        instance.loginPopup(scope).catch((e) => {
            console.log(e);
        });
    };
    return (
        <Button
            className="justify-content-end sharp-corner"
            variant="dark"
            onClick={() => handleLogin()}
        >
            {t("login")}
        </Button>
    );
};
