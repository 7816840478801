import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

function CopyableAlert({ children }) {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        const message = document.getElementById("alert-message").innerText;
        navigator.clipboard.writeText(message);
        setCopied(true);
    };

    return (
        <>
            <Alert variant="dark" className="code-block">
                <div id="alert-message">{children}</div>
                <Button
                    variant="outline-light"
                    onClick={copyToClipboard}
                    className="ml-2 copy-button"
                >
                    {copied ? "Copied!" : "Copy"}
                </Button>
            </Alert>
        </>
    );
}

export default CopyableAlert;
