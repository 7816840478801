export const Operation = Object.freeze({
    LOGIN: "Login",
    UPLOAD: "Upload",
});

export const Status = Object.freeze({
    START: "Start",
    PENDING: "Pending",
    SUCCESS: "Success",
    CANCEL: "Cancel",
    ERROR: "Error",
});

export const Source = "bdip-drop";
