import React from "react";
import { Container } from "react-bootstrap";

const FooterSection = () => {
    return (
        <Container className="d-flex justify-content-end container-align align-items-center footer-align">
            <img
                className="footer-logo"
                src="https://www.canada.ca/etc/designs/canada/cdts/gcweb/v4_0_29/assets/wmms-blk.svg"
                height="40px"
                alt="Symbol of the Government of Canada"
            />
        </Container>
    );
};

export default FooterSection;
